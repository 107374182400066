<template>
    <div class="page-wrap" v-loading="loading">
      <div class="page-aside">
       <ProductsSide @change="changeProduct" :nav="productList" :defaultActive="prodID"></ProductsSide>
      </div>
      <div class="page-main flex-column">
       
       
        <div class="main padding-20">
          <el-row>
            <el-col :span="24">
              <div class="font-size-20 line-height-large">
                价格统计分析
                <el-select v-model="freqValue"  size="mini" placeholder="请选择" @change="changefreq" class="margin-left-15">
                  <el-option
                    v-for="item in freqOptions"
                    :key="item.value"
                    :label="item.label"
                   
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
        </el-row>
         <div class="column  margin-top-20">
          <el-row>
              <el-col :span="24" class="font-size-16 line-height-large">
                  预测目标和滨化价格之间的关系
              </el-col>
          </el-row>
          <el-row :gutter="20" type="flex" class="margin-top-5">
              <el-col :span="hasbefarData?17:24" >
                  <Chart :option="chart_opt" ></Chart>
              </el-col>
              <el-col :span="7" v-if="hasbefarData">
                  <div class="font-size-14 line-height-large ">
                      <div><span class="color-sub ">预测目标:</span>{{chartdataList.item.targetDataName}}</div>
                      <div class="margin-top-5"><span class="color-sub">滨化价格：</span>{{chartdataList.item.befarDataName}}</div>
                      <div class="margin-top-5"><span class="color-sub">统计周期：</span>{{chartdataList.item.start}} 至 {{chartdataList.item.end}}</div>
                      <div class="margin-top-5"><span class="color-sub">相关系数：</span>{{this.$numFormat(this.chartdataList.item.coefficient,2)}}</div>
                      <div class="margin-top-5"><span class="color-sub">线性关系：</span>滨化价格={{this.$numFormat(chartdataList.item.equationA,2)}}x预测目标价{{this.$numFormat(chartdataList.item.equationB,2)>0?'+'+this.$numFormat(chartdataList.item.equationB,2):this.$numFormat(chartdataList.item.equationB,2)}}</div>
                  </div>
              </el-col>
          </el-row>
          </div>
          <div class="column  margin-top-20">
            <el-row>
                <el-col :span="24" class="font-size-14 line-height-large ">
                    样本统计描述
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" class="font-size-12 line-height-mini  color-sub">
                    统计周期：{{this.samplesttime.start}} 至 {{this.samplesttime.end}}
                </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" class="margin-top-15">
                <el-col :span="24" >
                  <el-table
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column label="样本名称">
                      <template slot-scope="props">{{ props.row['样本名称'] }}</template>
                    </el-table-column>
                    <el-table-column label="样本量">
                      <template slot-scope="props">{{ props.row['数据量'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="均值">
                      <template slot-scope="props">{{ props.row['均值'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column  label="标准差">
                      <template slot-scope="props">{{ props.row['标准差'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="众数">
                      <template slot-scope="props">{{ props.row['众数'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="最小值">
                      <template slot-scope="props">{{ props.row['最小值'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="25分位数">
                      <template slot-scope="props">{{ props.row['25分位数'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="中位数">
                      <template slot-scope="props">{{ props.row['中位数'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="75分位数">
                      <template slot-scope="props">{{ props.row['75分位数'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="最大值">
                      <template slot-scope="props">{{ props.row['最大值'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="极差">
                      <template slot-scope="props">{{ props.row['极差'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="IQR">
                      <template slot-scope="props">{{ props.row['IQR'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="峰度">
                      <template slot-scope="props">{{ props.row['峰度'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                    <el-table-column label="偏度">
                      <template slot-scope="props">{{ props.row['偏度'] | numFormat(2,'-') }}</template>
                    </el-table-column>
                  </el-table>
                </el-col>
                
            </el-row>
          </div>
          <el-row :gutter="20">
            <el-col :span="this.befarChartline.dataList&&this.befarChartline.dataList.length>0?12:24">
              <div class="column" v-if="freqValue!='day'">
                  <el-row>
                      <el-col :span="24" class="font-size-14 line-height-large ">
                                预测目标趋势
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="24" class="font-size-12 line-height-mini  color-sub">
                                统计周期：{{this.targetChartline.start}}至{{this.targetChartline.end}}
                      </el-col>
                  </el-row>
                  <Chart :option="charttarget_opt" ></Chart>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="column" v-if="freqValue!='day' && this.befarChartline.dataList&&this.befarChartline.dataList.length>0">
                <el-row>
                      <el-col :span="24" class="font-size-14 line-height-large ">
                                滨化价格趋势
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="24" class="font-size-12 line-height-mini  color-sub">
                                统计周期：{{this.befarChartline.start}}至{{this.befarChartline.end}}
                      </el-col>
                  </el-row>
                  <Chart :option="chartbefar_opt" ></Chart>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="this.befarAnalysis.dataList&&this.befarAnalysis.dataList.length>0?12:24">
              <div class="column">
                  <el-row>
                      <el-col :span="24" class="font-size-14 line-height-large ">
                        预测目标统计分析
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="24" class="font-size-12 line-height-mini  color-sub">
                               <div class="flex-row">
                                <div class="margin-right-15">样本量：{{ $numFormat(this.targetAnalysis.sampleCnt,2)}}</div>
                                <div  class="margin-right-15">极差：{{ $numFormat(this.targetAnalysis.subValue,2)}}</div>
                                <div  class="margin-right-15">组数：{{ $numFormat(this.targetAnalysis.groupCnt,2)}}</div>
                                <div  class="margin-right-15">组距：{{ $numFormat(this.targetAnalysis.groupRange,2)}}</div>
                               </div>
                      </el-col>
                  </el-row>
                  <Chart :option="charttargetansy_opt" ></Chart>
              </div>
            </el-col>
            <el-col :span="12" v-if="this.befarAnalysis.dataList&&this.befarAnalysis.dataList.length>0">
              <div class="column">
                <el-row>
                      <el-col :span="24" class="font-size-14 line-height-large ">
                        滨化价格统计分析
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="24" class="font-size-12 line-height-mini  color-sub">
                        <div class="flex-row">
                                <div class="margin-right-15">样本量：{{$numFormat( this.befarAnalysis.sampleCnt,2)}}</div>
                                <div  class="margin-right-15">极差：{{$numFormat( this.befarAnalysis.subValue,2)}}</div>
                                <div  class="margin-right-15">组数：{{$numFormat( this.befarAnalysis.groupCnt,2)}}</div>
                                <div  class="margin-right-15">组距：{{$numFormat( this.befarAnalysis.groupRange,2)}}</div>
                               </div>
                      </el-col>
                  </el-row>
                  <Chart :option="chartbefaransy_opt" ></Chart>
              </div>
            </el-col>
          </el-row>
        </div>
       
      </div>  
    </div> 
</template>

<script>
import Chart from "@/components/Chart";
import ProductsSide from '@/components/ProductsSide.vue';
export default {
  name: 'Entry',
  components: { ProductsSide,Chart },
  data() {
    return {
      loading:false,
      productList:[],
      freqValue:'week',
      freqOptions: [{
          value: 'day',
          label: '日度'
        }, {
          value: 'week',
          label: '周度'
        }, {
          value: 'month',
          label: '月度'
        }, {
          value: 'quarter',
          label: '季度'
        }, {
          value: 'year',
          label: '年度'
        }],
      tableData: [],
      samplesttime:{start:"",end:""},
      chartdataList:{
        item:{},
        dataList:[]
      },
      targetChartline:{},
      befarChartline:{},
      targetAnalysis:{},
      befarAnalysis:{},
      prodID:0,
      defaultproductid:0,
      canStyle: {
        color: '#fd6864' ,
        color0: '#11bf99' ,
        borderColor: '#fd6864' ,
        borderColor0: '#11bf99' 
      },
     
    }
  },
  computed:{
    hasbefarData(){
      return this.chartdataList.dataList.filter(o=>{return o.berfarValue!=null}).length>0
      
    },
    chart_opt(){
      if(this.chartdataList.dataList.length>0){
            let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
          opt.dataZoom.startValue=Math.max((this.chartdataList.dataList||[]).findIndex(v=>{return this.$numFormat(v.targetValue)}),(this.chartdataList.dataList||[]).findIndex(v=>{return this.$numFormat(v.berfarValue)}))
          opt.legend.data=this.hasbefarData?[
            {
                name:'预测目标',
                unit: this.chartdataList.item.targetUnit||"",
                itemStyle:{
                    opacity:0
                }
            },
            {
                name:'滨化价格',
                unit: this.chartdataList.item.befarUnit||"",
                itemStyle:{
                    opacity:0
                }
            }
          ]:[{
                name:'预测目标',
                unit: this.chartdataList.item.targetUnit||"",
                itemStyle:{
                    opacity:0
                }
            }]
            opt.tooltip.formatter = (params)=>{
              var str=`<div>${params[0].axisValue}</div>`
              params.forEach((val)=>{
                if(val.data){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
                  
              })
              return str
            }
          opt.yAxis.name = this.chartdataList.item.targetUnit||""
          opt.xAxis.boundaryGap = true
          opt.xAxis.data=this.chartdataList.dataList?this.chartdataList.dataList.map(o=>{return o.date}):[]
          opt.series=this.hasbefarData?[
            {
                name:"预测目标",
                data: this.chartdataList.dataList?this.chartdataList.dataList.map(o=>{return this.$numFormat(o.targetValue,2)}):[],
                type: 'line',
                showAllSymbol:false,
                connectNulls: true,
                unit:this.chartdataList.item.targetUnit||""
            },
            {
                name:'滨化价格',
                data: this.chartdataList.dataList?this.chartdataList.dataList.map(o=>{return this.$numFormat(o.berfarValue,2)}):[],
                type: 'line',
                showAllSymbol:false,
                connectNulls: true,
                unit:this.chartdataList.item.befarUnit||""
            }
          ]:[ {
                name:"预测目标",
                data: this.chartdataList.dataList?this.chartdataList.dataList.map(o=>{return this.$numFormat(o.targetValue,2)}):[],
                type: 'line',
                showAllSymbol:false,
                connectNulls: true,
                unit:this.chartdataList.item.targetUnit||""
            }]
          return opt
      }else{
        return null
      }
      
    },
    targettrendLegend(){return [
          {
              name:'平均值',
              itemStyle:{
                  opacity:0
              },
              unit:this.targetChartline.items &&this.targetChartline.items[0] ?this.targetChartline.items[0].unit:""
          },
          {
              name:'价格走势',
              unit:this.targetChartline.items&&this.targetChartline.items[1]?this.targetChartline.items[1].unit:"",
              itemStyle:{
                borderWidth:0,
                color:{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#fd6864' // 0% 处的颜色
                    },{
                        offset: 0.5, color: '#fd6864' // 50% 处的颜色
                    },{
                        offset: 0.51, color: '#11bf99' // 51% 处的颜色
                    }, {
                        offset: 1, color: '#11bf99' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
              }
          }
    ]},
    charttarget_opt(){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.legend.data=this.targettrendLegend
        opt.dataZoom.startValue=(this.targetChartline.dataList||[]).length-36
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=this.targetChartline.dataList?this.targetChartline.dataList.map(o=>{return o.dataMonth}):[]
        opt.tooltip.formatter = (params)=>{
            var str=`<div>${params[0].axisValue}</div>`
            params.forEach((val)=>{
              if(val.seriesType=='candlestick'){
                if(val.data.length>0){
                  str+=`<div>${val.marker}${val.seriesName}</div>
                      <div>期初值：${this.$numFormat(val.data[1],2)}${opt.legend.data[val.seriesIndex].unit}</div>
                      <div>期末值：${this.$numFormat(val.data[2],2)}${opt.legend.data[val.seriesIndex].unit}</div>
                      <div>最低值：${this.$numFormat(val.data[3],2)}${opt.legend.data[val.seriesIndex].unit}</div>
                      <div>最高值：${this.$numFormat(val.data[4],2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
               
              }else{
                if(val.data){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
                
              }
            })
            return str
          }
        opt.series=[
          {
              name:"平均值",
              data: this.targetChartline.dataList?this.targetChartline.dataList.map(o=>{return o.avg}):[],
              type: 'line',
              showAllSymbol:false,
              connectNulls: true
          },
          {
              name:'价格走势',
              data: this.targetChartline.dataList?this.targetChartline.dataList.map(o=>{return o.trend}):[],
              type: 'candlestick',
              itemStyle: this.canStyle,
              showAllSymbol:false,
              connectNulls: true
          }
        ]
        return opt
    },
    chartbefar_opt(){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.legend.data=this.targettrendLegend
        opt.dataZoom.startValue=(this.befarChartline.dataList||[]).length-36
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=this.befarChartline.dataList?this.befarChartline.dataList.map(o=>{return o.date}):[]
        opt.tooltip.formatter = (params)=>{
            var str=`<div>${params[0].axisValue}</div>`
            params.forEach((val)=>{
              if(val.seriesType=='candlestick'){
                if(val.data.length>0){
                  str+=`<div>${val.marker}${val.seriesName}</div>
                      <div>期初值：${this.$numFormat(val.data[1],2)}${opt.legend.data[val.seriesIndex].unit}</div>
                      <div>期末值：${this.$numFormat(val.data[2],2)}${opt.legend.data[val.seriesIndex].unit}</div>
                      <div>最低值：${this.$numFormat(val.data[3],2)}${opt.legend.data[val.seriesIndex].unit}</div>
                      <div>最高值：${this.$numFormat(val.data[4],2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
               
              }else{
                if(val.data){
                  str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit}</div>`
                }
                
              }
            })
            return str
        }
        opt.series=[
          {
              name:"平均值",
              data: this.befarChartline.dataList?this.befarChartline.dataList.map(o=>{return o.avg}):[],
              type: 'line',
              showAllSymbol:false,
              connectNulls: true
          },
          {
              name:'价格走势',
              data: this.befarChartline.dataList?this.befarChartline.dataList.map(o=>{return o.trend}):[],
              type: 'candlestick',
              itemStyle: this.canStyle,
              showAllSymbol:false,
              connectNulls: true
          }
        ]
        return opt
    },
    charttargetansy_opt(){
      let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
      opt.dataZoom.show=false
        opt.yAxis={
          type: 'category',
          data: this.targetAnalysis.items
        },
        opt.xAxis.type='value';
        opt.series=[
          {
              name:'样本量',
              data: this.targetAnalysis.dataList?this.targetAnalysis.dataList.map(o=>{return this.$numFormat(o,2)}):[],
              type: 'bar',
          }
        ]
        return opt
    },
    chartbefaransy_opt(){
      let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
      opt.dataZoom.show=false
        opt.yAxis={
          type: 'category',
          data: this.befarAnalysis.items
        },
        opt.xAxis.type='value';
        opt.series=[
          
          {
              name:'样本量',
              data: this.befarAnalysis.dataList?this.befarAnalysis.dataList.map(o=>{return this.$numFormat(o,2)}):[],
              type: 'bar',
          }
        ]
        return opt
    }
  },
  methods:{
    changefreq(){
        this.init();
    },
    getProducts(){
      this.loading=true
      let request=this.$instance.get('/manage/getProductPower',{
          params: {
           ...this.$store.state.basicParams,
           moduleCode:'mu_biao_fen_xi'
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.productList=res.data.info
          this.checkProAuth()
        }else{
          this.$router.replace({
            path:'/error',
            query:{
              redirect:this.$route.fullPath
            }
          })
        }
      })
      request.catch(()=>{
        this.loading=false
        this.$router.replace({
          path:'/error',
          query:{
            redirect:this.$route.fullPath
          }
        })
      })
      return request
    },
    checkProAuth(){
      if(!this.productList.length){
        this.$alert(`您暂无目标分析模块的产品权限`, '提示', {
          customClass:"custom-msgbox",
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {
          this.$router.replace({
            path:'/'
          })
        })
      }else{
        this.prodID=this.productList[0].productId
        this.init()
      }
    },
    changeProduct(item){
      this.prodID=item.productId;
      this.init();
    },
    getTargetpricediagram(){
      this.chartdataList={
        item:{},
        dataList:[]
      }
      var _this=this;
      this.$instance({
        url: 'targetAnalysis/getTargetPriceDiagram',
        method: 'get',
        params:{productId:_this.prodID,modelFreq:_this.freqValue}
      }).then(res=>{
         
          _this.chartdataList=res.data.info
      })
    },
    getSamplestatistical(){
      var _this=this;
      _this.tableData=[];
      this.$instance({
        url: 'targetAnalysis/getSamplestatistical',
        method: 'get',
        params:{productId:_this.prodID,modelFreq:_this.freqValue}
      }).then(res=>{
          res.data.info.sci["样本名称"]="预测目标";
          res.data.info.befar["样本名称"]="滨化价格";
          _this.tableData.push(res.data.info.sci)
          _this.tableData.push(res.data.info.befar);
          _this.samplesttime.start=res.data.info.start;
          _this.samplesttime.end=res.data.info.end;
      })
    },
    getTargePriceTrend(){
      //预测目标趋势
      
      if(this.freqValue!='day'){
        var _this=this;
        _this.targetChartline={}
        this.$instance({
          url: 'targetAnalysis/getTargePriceTrend',
          method: 'get',
          params:{productId:_this.prodID,modelFreq:_this.freqValue}
        }).then(res=>{
          
            _this.targetChartline=res.data.info;
        })
      }
    },
    getBefarTargePriceTrend(){
      //滨化趋势
      if(this.freqValue!='day'){
        var _this=this;
        _this.befarChartline={}
        this.$instance({
          url: 'targetAnalysis/getBefarTargePriceTrend',
          method: 'get',
          params:{productId:_this.prodID,modelFreq:_this.freqValue}
        }).then(res=>{
            
            _this.befarChartline=res.data.info;
        })
      }
     
    },
    getTargetSampleStatisticalGroup(){
      //预测目标统计分析
      var _this=this;
      _this.targetAnalysis={}
      this.$instance({
        url: 'targetAnalysis/getTargetSampleStatisticalGroup',
        method: 'get',
        params:{productId:_this.prodID,modelFreq:_this.freqValue}
      }).then(res=>{
        
          _this.targetAnalysis=res.data.info;
      })
    },
    getBefarTargetSampleStatisticalGroup(){
      //滨化目标统计分析
      var _this=this;
      _this.befarAnalysis={}
      this.$instance({
        url: 'targetAnalysis/getBefarTargetSampleStatisticalGroup',
        method: 'get',
        params:{productId:_this.prodID,modelFreq:_this.freqValue}
      }).then(res=>{
           _this.befarAnalysis=res.data.info;
      })
    },
    init(){
      if(this.prodID){
        this.getTargetpricediagram();
        this.getSamplestatistical();
        this.getTargePriceTrend();
        this.getBefarTargePriceTrend();
        this.getTargetSampleStatisticalGroup();
        this.getBefarTargetSampleStatisticalGroup();
      }
    }
  },
  watch: {

  },
  mounted(){
    this.getProducts();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
